<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
      

        <banner
        title="ADMIN PROFILE"
          :breadcrumb="[
           {
  label: 'Dashboard',
},
{
  label: (admin.portal === 'true') ? 'Platform Module' : 'Franchise Module',
},
{
  label: 'Admins',
},
{
  label: 'Profile',
}
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-sm-5"  style="padding: 5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
        
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Admin Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Admin Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="adminlogofinder(admin)"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="admin.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 3px;
                          padding-bottom: 3px;
                          padding-left: 5px;
                          padding-right: 5px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="admin">{{ admin.code }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="admin" style="text-transform: capitalize">{{
                        admin.name
                      }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="admin"> {{ dateTime(admin.dob) }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="admin" style="text-transform: capitalize">{{
                        admin.gender
                      }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="admin">+91-{{ admin.phone }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhaar Number : </b>
                      <span v-if="admin.admin_kyc">{{ admin.admin_kyc.aadharNo }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>PAN : </b>
                      <span v-if="admin.admin_kyc" style="text-transform: uppercase">{{
                        admin.admin_kyc.panNo
                      }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Address : </b>
                      <span v-if="admin" style="text-transform: capitalize"
                        >{{ admin.address }} {{ admin.city }} {{ admin.pin }}</span
                      >
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="adminlogofinder(admin)"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="admin.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>

                <!-- bussiness Detais  -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Business Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Firm Name: </b>
                      <span>{{ admin.orgnization }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Address : </b>
                      <span
                        >{{ admin.businessAddress }} {{ admin.businessCity }}
                        {{ admin.businessPin }}
                      </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Pan : </b>
                      <span v-if="admin">{{ admin.businessPan }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Official Email Id : </b>
                      <span v-if="admin">{{ admin.email }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOI : </b>
                      <span v-if="admin"> {{ dateTime(admin.doi) }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="admin.state"
                        >{{ admin.state.name }} ,{{ admin.state.shortName }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>GSTIN : </b>
                      <span v-if="admin">{{ admin.gstin }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>TAN : </b>
                      <span v-if="admin">{{ admin.tds }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>CIN : </b>
                      <span v-if="admin">{{ admin.cin }}</span>
                    </div>
                  </div>
                </div>
                <!-- end  bussiness Detais  -->

                <!-- end Qualifications -->

                <!-- Specialisation -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder"
                      >Credentials Details
                    </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Portal : </b>
                      <span v-if="admin.portal == 'true'">Platform Module</span>

                      <span v-if="admin.portal == 'false'">Franchise Module</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Secret Key : </b>
                      <span v-if="admin">{{ admin.secretKey }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Call Back Url : </b>
                      <span v-if="admin">{{ admin.callbackUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Success Url : </b>
                      <span v-if="admin">{{ admin.successUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Failed Url : </b>
                      <span v-if="admin">{{ admin.failedUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>End Point : </b>
                      <span v-if="admin">{{ admin.endpoint }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Method : </b>
                      <span v-if="admin">{{ admin.method }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Parameter : </b>
                      <span v-if="admin">{{ admin.parameter }}</span>
                    </div>
                  </div>
                </div>
                <hr />

                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="admin">{{ admin.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="admin">{{dateTimeWithTime(admin.last_login) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-lg-7 col-md-7 col-12" style="padding: 5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="row" v-if="this.wallet.id">
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>
                  <!-- Qr Code responsive Mobile screen  -->
                  <!-- <div
                    class="d-xl-none d-md-none d-lg-none d-block col-sm-12 col-12 text-center"
                  >
                    <img style="width: 40%" :src="wallet.qr_path" alt="" />
                  </div> -->
                  <!-- end Qr Code responsive Mobile screen  -->
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Balance : </b>
                      <span v-if="this.wallet">Rs.{{ this.wallet.balanceAmount }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Virtual Account : </b>
                      <span v-if="this.wallet">{{ this.wallet.merchant_vpa }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Code : </b>
                      <span v-if="this.wallet">{{ this.wallet.wallet }} </span>
                    </div>

                    <span style="color: #00364f" v-if="this.wallet">
                      <p
                        v-if="this.wallet.status == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <!-- Qr Code responsive Pc screen  -->
                  <!-- <div
                    class="col-xl-2 col-lg-2 col-md-2 d-none d-xl-block d-lg-block d-md-block"
                  >
                    <img style="width: 100%" :src="wallet.qr_path" alt="qr code" />
                  </div> -->
                  <!-- end Qr Code responsive Pc screen  -->
                </div>
                <div class="row" v-else>
                  <div class="col">
                    <button
                    @click="walletFormNull()"
                      type="button"
                      class="btn text-white btn-sm"
                      style="
                        background-color: #f21300;
                        font-size: 13px;
                        letter-spacing: 1px;
                      "
                      data-bs-toggle="modal"
                      data-bs-target="#walletModel"
                    >
                      <b> <font-awesome-icon icon="plus" /> Create Wallet </b>
                    </button>
                  </div>
                </div>
                <hr v-if="wallet.id" style="color: #e3e3e3" />
                <div class="row" v-if="wallet.id">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-start" style="padding:5px 5px 5px 0px">
                    <h4><b style="color: #00364f; font-weight: bolder">Withdrawls</b></h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end" style="padding:5px 0px 5px 5px">

                    <!-- <b style="color: #00364f; font-weight: bolder"><button @click="withdrawalFormNull()" type="button"
                        class="btn text-white btn-sm" style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          " data-bs-toggle="modal" data-bs-target="#walletWithDrawModel">
                        <b> <font-awesome-icon icon="plus" /> Withdrawls </b>
                      </button></b> -->

                    <!-- &nbsp;
                    <router-link style="
                        color: #ffff;
                            background-color: #00364f;
                            font-size: 13px;
                            letter-spacing: 1px;
                          " to="/master-distributer/wallet-Withdraw-Req" type="button" class="btn btn-sm my-btn">
                      <small> View All </small>

                    </router-link> -->

                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text" style="font-size: 14px">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">UPI</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Payment Mode</th>

                          <th class="text-truncate">Status</th>
                        </tr>

                        <tbody>
                          <tr v-for="(walletWithdrawReq, index) in walletwithdrawalsReq" :key="index">
                            <td>
                              <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png" alt="" />
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.amount }}
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.beneficiaryaccount">
                                {{ walletWithdrawReq.beneficiaryaccount.account_number }}
                              </span>

                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="walletWithdrawReq.merchant_vpa">
                                {{ walletWithdrawReq.merchant_vpa }}
                              </span>
                              <span v-else> NA </span>
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ dateTime(walletWithdrawReq.date) }}
                            </td>
                            <td class="text-truncate" v-if="walletWithdrawReq">
                              {{ walletWithdrawReq.paymentMode }}
                            </td>

                            <td class="text-truncate" v-if="walletWithdrawReq">
                              <p v-if="walletWithdrawReq.status == 'complete'" class="text-white btn btn-sm" style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'cancel'" class="text-white btn btn-sm" style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>

                              <p v-if="walletWithdrawReq.status == 'pending'" class="text-white btn btn-sm" style="
                                    background-color: #fc7f03;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                              <p v-if="walletWithdrawReq.status == 'reject'" class="text-white btn btn-sm" style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                <b> {{ walletWithdrawReq.status }}</b>
                              </p>
                            </td>
                          
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr v-if="this.wallet.id" style="color: #e3e3e3" />

                <!-- start list area  -->
                <div v-if="this.wallet.id" class="row mt-3">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Beneficiary Account</b
                      >
                    </h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end" >
                    <!-- <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        ><button
                          type="button"
                          class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                            box-shadow: 2px 2px 2px gray;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <b> <font-awesome-icon icon="plus" /> Bank Account </b>
                        </button></b
                      >
                    </h4> -->
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" >
                    <div class="container-fluid table-scroll">
                      <table
                        class="table table-hover table-sm text"
                        style="font-size: 14px"
                      >
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th class="text-truncate">+</th>
                          <th class="text-truncate">Ac. Holder</th>
                          <th class="text-truncate">Ac No.</th>
                          <th class="text-truncate">Account Type</th>
                          <th class="text-truncate">Ifsc</th>
                          <th class="text-truncate">Bank</th>
                          <th class="text-truncate">Mobile No</th>
                          <th class="text-truncate">Status</th>
                          <!-- <th class="text-truncate">Action</th> -->
                        </tr>

                        <tbody>
                          <tr
                            v-for="(beneficiaryaccount, index) in beneficiaryaccounts"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_holder_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_number }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.account_type }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.ifsc_code }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.bank_name }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              {{ beneficiaryaccount.mobile }}
                            </td>
                            <td class="text-truncate" v-if="beneficiaryaccount">
                              <p
                                v-if="beneficiaryaccount.status == 'pending'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> {{ beneficiaryaccount.status }}</b>
                              </p>
                            </td>
                            <!-- <td class="text-truncate text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  @click="editBeneficiaryAcount(beneficiaryaccount)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                      <div class="text-center text-danger" v-if="beneficiaryaccounts.length == 0">
                            <i>No Record Found </i>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal fade"
    id="walletModel"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    
  >
    <div class="modal-dialog">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f;"
          >
            <b>Wallet </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              walletEdit == 'false' ? adminWalletCreateDone() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="walletForm.errors">
                <div v-if="walletForm.errors.message">
                  <div class="alert alert-danger p-1" role="alert">
                    {{ walletForm.errors.message }}
                  </div>
                </div>
                <div v-if="walletForm.errors.error">
                  <div v-if="walletForm.errors.error.admin_id">
                    <div class="alert alert-danger p-1" role="alert">
                      {{ walletForm.errors.error.admin_id[0] }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-12 col-12 p-1"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Mobile Number  <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input
                  required
                    type="number"
                    v-model="walletForm.mobile"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Mobile Number"
                    @change="lenthheker($event, 'phoneNumberWallet')"
                  />
        
                </div>
                <span style="color: #f21000" id="phoneNumberWallet"></span>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.mobile">{{
                      walletForm.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12 p-1"
                style="padding: 5px 5px 5px 5px"
              >
                <label>UPI ID  <small style="color:#f21000">*</small></label>

                <div class="from-group">
                  <input
                  required

                    type="text"
                    v-model="walletForm.merchant_vpa"
                    class="form-control"
                    style="height: 30px"
                    placeholder="UPI ID"
                    @change="lenthheker($event, 'upiWallet')"
                  />
                </div>
                <span style="color: #f21000" id="upiWallet"></span>
                <small style="color: red" v-if="walletForm.errors"
                  ><span v-if="walletForm.errors.error"
                    ><span v-if="walletForm.errors.error.merchant_vpa">{{
                      walletForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!--start model  -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>Beneficiary Account </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              edit == 'false' ? beneficiaryaccountAdd() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account Holder Name</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.account_holder_name"
                    style="height: 30px"
                    placeholder="Account Holder"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    class="form-control"
                    style="height: 30px"
                    v-model="form.account_holder_name"
                    placeholder="Account Holder"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.account_holder_name">{{
                      form.errors.error.account_holder_name[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account Number</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Account Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.account_number"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Account Number"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.account_number">{{
                      form.errors.error.account_number[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="row mt-1">
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>IFSC</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.ifsc_code"
                    class="form-control"
                    style="height: 30px"
                    placeholder="IFSC "
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.ifsc_code"
                    class="form-control"
                    style="height: 30px"
                    placeholder="IFSC "
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.ifsc_code">{{
                      form.errors.error.ifsc_code[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Bank Name</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Bank Name"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="form.bank_name"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Bank Name"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.bank_name">{{
                      form.errors.error.bank_name[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="row mt-1">
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Phone Number</label>
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="form.mobile"
                    class="form-control"
                    placeholder=" Mobile Number"
                    style="height: 30px"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="form.mobile"
                    class="form-control"
                    style="height: 30px"
                    placeholder=" Mobile Number"
                  />
                </div>
                <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.mobile">{{
                      form.errors.error.mobile[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Account type</label>
                <div class="from-group">
                  <select
                    class="form-select"
                    v-model="form.account_type"
                    aria-label="Default select example"
                    placeholder="Status"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                  >
                    <option value="savings" selected>Savings account</option>
                    <option value="current">Current account</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
  <!--start wallet withdrawles model  -->
  <div
    class="modal fade"
    id="walletWithDrawModel"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>Withdrawal </b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              withdrawalEdit == 'false'
                ? superadminWalletWithdrawal()
                : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-12" v-if="walletwithdrawalForm.errors">
                <div v-if="walletwithdrawalForm.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ walletwithdrawalForm.errors.message }}
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Amount</label>

                <div class="from-group">
                  <input
                    type="number"
                    v-model="walletwithdrawalForm.amount"
                    class="form-control"
                    style="height: 30px"
                    placeholder="Mobile Number"
                  />
                </div>
                <small style="color: red" v-if="walletwithdrawalForm.errors"
                  ><span v-if="walletwithdrawalForm.errors.error"
                    ><span v-if="walletwithdrawalForm.errors.error.amount">{{
                      walletwithdrawalForm.errors.error.amount[0]
                    }}</span></span
                  ></small
                >
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Payment Mode</label>
                <select
                  @change="paymantModeValueAssign()"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.paymentMode"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="upi">UPI</option>
                  <option value="imps">IMPS</option>
                  <option value="neft">NEFT</option>
                  <option value="rtgs" disabled>RTGS</option>
                </select>
              </div>

              <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode != 'upi'"
              >
                <label>Beneficiaryaccount Account</label>
                <select
                  @change="paymantModeValueAssign()"
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.beneficiaryaccount_id"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option
                    v-for="(beneficiaryaccount, index) in beneficiaryaccounts"
                    :key="index"
                    :value="beneficiaryaccount.id"
                  >
                    {{ beneficiaryaccount.account_number }}
                  </option>
                </select>
              </div>
              <!-- <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode != 'upi'"
              >
                <label>Payment Mode</label>
                <select
                  style="height: 30px; padding: 5px 5px 5px 5px"
                  v-model="walletwithdrawalForm.beneficiaryaccount_type"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="current">Current Acount</option>
                  <option value="savings">Savings Acount</option>
                </select>
              </div> -->
              <div
                class="col-xl-6 col-md-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="walletwithdrawalForm.paymentMode == 'upi'"
              >
                <label>UPI </label>

                <div class="from-group">
                  <input
                    type="text"
                    v-model="walletwithdrawalForm.merchant_vpa"
                    class="form-control"
                    style="height: 30px"
                    placeholder="UPI Number"
                  />
                </div>
                <small style="color: red" v-if="walletwithdrawalForm.errors"
                  ><span v-if="walletwithdrawalForm.errors.error"
                    ><span v-if="walletwithdrawalForm.errors.error.merchant_vpa">{{
                      walletwithdrawalForm.errors.error.merchant_vpa[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="
                  background-color: #f21300;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="
                  background-color: #00364f;
                  box-shadow: 1px 2px 5px gray;
                  text-shadow: 1px 1px 5px gray;
                "
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end wallet withdrawles   modal -->
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminAdminProfile",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      walletwithdrawalForm: {
        wallet_id: null,
        amount: null,
        paymentMode: "",
        beneficiaryaccount_id: null,
        beneficiaryaccount_type: "",
        merchant_vpa: "",
        remark: "",
        errors: {},
      },
      withdrawalEdit: "false",
      walletwithdrawalsReq: [],
      loading: false,
      edit: "false",
      walletEdit: "false",
      profileImg: "",
      services: [],
      adminprofile: {},
      admin: {},
      walletForm: {
        admin_id: this.$route.params.id,
        merchant_vpa: "",
        mobile: "",
        errors: {},
      },
      wallet: {},
      beneficiaryaccounts: [],
      beneficiary_Form_id: null,
      form: {
        account_number: "",
        account_type: "saving",
        account_holder_name: "",
        ifsc_code: "",
        bank_name: "",
        bank_branch: "Agra",
        mobile: "",
        status: "",
        remark: "",
        ipAddress: "",
        errors: {},
      },
      adminKycs: {},

      qrDataRec: {},
      qrPayments: {},
      trCollactions: [],
      addmoneyForm: {
        payerVa: "",
        amount: "",
        note: "",
      },
      qrForm: {
        amount: 100,
      },
      TransectionsGet: [],
      phoneNumberWallet:'false',
      upiWallet:'fasle',
    };
  },

  methods: {
    superadminWalletWithdrawalGet() {
      // this.walletwithdrawalForm.wallet_id = this.wallet.id;
      this.loading = true;
      this.$axios
        .get("superadmin/adminwalletwithdrawal?per_page=5", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("Req =====");
          console.log(res.data.data.data);
          console.log("Req =====");

          this.walletwithdrawalsReq = res.data.data.data;
        })
        .catch((error) => {
          this.walletwithdrawalForm.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
    },
    walletFormNull(){
      this.walletForm = {}
      this.walletForm.errors = {}
    },
    lenthheker(event, type) {

      var data = event.target.value;
      if (type == "phoneNumberWallet") {
        if (data.length != 10) {
          this.phoneNumberWallet = "true";
          $("#phoneNumberWallet").html("<small>Phone Required  10 Digits</small>");
        }
        if (data.length == 10) {
          this.phoneNumberWallet = "false";
          $("#phoneNumberWallet").html("<small></small>");
        }
      }

      if (type == "upiWallet") {
        if (data.length < 3) {
          this.upiWallet = "true";
          $("#upiWallet").html("<small> Required Minimum 3 Metter</small>");
        }else {
          this.upiWallet = "false";
          $("#upiWallet").html("<small></small>");
        }
      }
    },
    loadBeneficiaryAccount() {
      this.$axios
        .get(`superadmin/adminbeneficiaryaccount?admin_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.beneficiaryaccounts = res.data.data.data;
        });
    },

    editBeneficiaryAcount(acount) {
      this.form_id = acount.id;
      this.beneficiaryId = acount.id;

      this.form = acount;
      this.edit = "true";
    },
    updatebeneficiaryAcount() {
      this.$axios
        .put(`supersuperadmin/adminbeneficiaryaccount/${this.beneficiaryId}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Beneficiary Account Updated successfully", {
            autoClose: 1000,
          });

          this.form = {};
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount();
        });
    },

    beneficiaryaccountAdd() {
      // start
      this.form.admin_id = parseInt(this.$route.params.id);
      this.$axios
        .post("superadmin/adminbeneficiaryaccount", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Beneficiary Account Created successfully", {
            autoClose: 1000,
          });
          this.form = {};
          this.form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          $("#exampleModal").modal("hide");
          this.loadBeneficiaryAccount();
        })
        .catch((error) => {
          // console.log(error.response);
          this.form.errors = error.response.data;
          // console.log(this.form.errors);
          //  alert(this.form.errors.error)
        });
      // end
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    modalHeading() {
      $("#modal-heading-update").html("new");
    },
    // createWallet() {
    //   this.$axios
    //     .post(
    //       `superadmin/adminwallet?user_id=${this.$route.params.id}`,
    //       this.beneficiary_Form,
    //       { headers: { Authorization: "Bearer " + localStorage.accessToken } }
    //     )
    //     .then((res) => {
    //       console.log(res.data.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Wallet Acount Created  Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.loadWallet();
    //     });
    // },

    // real done

    adminWalletCreateDone() {
      if(this.upiWallet == 'false' && this.phoneNumberWallet == 'false'){
        this.walletForm.admin_id = this.$route.params.id
        this.loading = true;
        this.$axios
        .post("superadmin/adminwallet", this.walletForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data);
          toast.success("Wallet Created Succesfully", {
            autoClose: 1000,
          });
          $("#walletModel").modal("hide");
          this.loadAdminInfo();
          this.loadWallet();
        }).finally(() => (this.loading = false))
        .catch((error) => {
          this.walletForm.errors = error.response.data
// this.$swal.fire({
//       position: "top-center",
//       icon: "error",
//       title: "<small>" + error.response.data.error + "</small>",
//       showConfirmButton: true,
//       confirmButtonColor: "#00364f",
//     });



  });
      }
    
    },

    loadWallet() {
      this.$axios
        .get(`superadmin/adminwallet/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("_______________wallet______________in_________");
          console.log(res.data.data);
          this.wallet = res.data.data;

          this.beneficiary_Form.beneficiary_id = this.wallet.customer_id;
          this.beneficiary_Form.admin_id = this.wallet.admin_id;
        });
    },
    createbeneficiaryAcount() {
      this.$axios
        .post("superadmin/adminbeneficiaryaccount", this.beneficiary_Form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(
            "____________create ___beneficiaryaccount______________in_________"
          );
          console.log(res.data.data.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Beneficiary Acount Created  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadBeneficiaryAccount();
        });
    },

    adminlogofinder(admin) {
      var img;
      if (admin.photo != null) {
        img = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.photo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    loadAdminInfo() {
      this.loading = true;
      this.$axios
        .get(
          `superadmin/admin/${this.$route.params.id}`,

          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.admin = res.data.data;
          //  console.log('------------------admin----------------------')
          //   console.log(this.admin)

          if (this.this.wallet) {
            this.wallet = this.this.wallet;
          }

          if (this.admin.logo != "null") {
            this.profileImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin.id +
              "/thumbs/" +
              this.admin.logo;
          } else {
            alert("hi");
          }
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.adminView
    console.log(this.$store.state.superAdminPageTitles.adminView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadAdminInfo();
      this.loadBeneficiaryAccount();
      this.superadminWalletWithdrawalGet();
      this.loadWallet();
      //   this.adminKycsLoading()
      
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
